import { addPropertyControls, ControlType } from "framer"
import { useState, useEffect } from "react"
import { motion } from "framer-motion"

function extractDarkModeCSS(cssString) {
    const regex = /@media\s*\(prefers-color-scheme:\s*dark\)\s*\{([^}]*)\}/
    const match = cssString.match(regex)
    return match ? match[1].trim() : ""
}

function extractDarkModeCSSVariables(css) {
    // Regular expression to match @media (prefers-color-scheme: dark) { ... }
    const regex =
        /@media\s+\(prefers-color-scheme:\s*dark\)\s*{([^}]*)body\s*{([^}]*)}}/
    const matches = css.match(regex)
    if (matches && matches[2]) {
        return matches[2].trim()
    }
    return ""
}

function extractLightModeCSSVariables(css) {
    // Match body block, excluding nested @media blocks
    const bodyRegex = /body\s*{([^}]*)}/g
    const mediaRegex =
        /@media\s+\(prefers-color-scheme:\s*dark\)\s*{[^}]*body\s*{[^}]*}}/g

    // Extract body block without @media blocks
    const bodyMatches = css.match(bodyRegex)
    const mediaMatches = css.match(mediaRegex)

    let bodyContent = ""
    if (bodyMatches) {
        bodyContent = bodyMatches[0]
    }

    // Remove media block content from bodyContent
    if (mediaMatches) {
        mediaMatches.forEach((mediaMatch) => {
            bodyContent = bodyContent.replace(mediaMatch, "")
        })
    }

    // Extract the CSS variables from the body block
    const cssVarRegex = /--[\w-]+:\s*[^;]+;/g
    const cssVars = bodyContent.match(cssVarRegex)

    return cssVars ? cssVars.join("\n") : ""
}

function setDarkThemeCode(code) {
    const styleId = "pix-dark-theme-style"

    // Check if a <style> element with the specified ID already exists
    let existingStyle = document.getElementById(styleId)
    if (existingStyle) {
        return // If it exists, do nothing
    }
    // Step 1: Create a <style> element
    const style = document.createElement("style")
    // Step 2: Set its content to the desired CSS string
    const cssString = `body.pix-is-dark, body:has(.pix-is-dark) {${code}}`
    style.textContent = cssString
    // Step 3: Set the ID of the <style> element
    style.id = styleId
    // Step 4: Append the <style> element to the <head>
    document.head.appendChild(style)
}

function setLightThemeCode(code) {
    const styleId = "pix-light-theme-style"

    // Check if a <style> element with the specified ID already exists
    let existingStyle = document.getElementById(styleId)
    if (existingStyle) {
        return // If it exists, do nothing
    }
    // Step 1: Create a <style> element
    const style = document.createElement("style")
    // Step 2: Set its content to the desired CSS string
    const cssString = `body.pix-is-light, body:has(.pix-is-light) {${code}}`
    style.textContent = cssString
    // Step 3: Set the ID of the <style> element
    style.id = styleId
    // Step 4: Append the <style> element to the <head>
    document.head.appendChild(style)
}

class EventEmitter {
    events: {}
    constructor() {
        this.events = {}
    }

    on(event, listener) {
        if (!this.events[event]) {
            this.events[event] = []
        }
        this.events[event].push(listener)
    }

    emit(event, data) {
        if (this.events[event]) {
            this.events[event].forEach((listener) => listener(data))
        }
    }

    off(event, listenerToRemove) {
        if (!this.events[event]) return

        this.events[event] = this.events[event].filter(
            (listener) => listener !== listenerToRemove
        )
    }
}

const eventEmitter = new EventEmitter()

class ThemeCheck {
    constructor() {
        const savedTheme = localStorage.getItem("theme")
        if (savedTheme) {
            if (savedTheme === "Dark") {
                document.body.classList.add("pix-is-dark")
                document.body.classList.remove("pix-is-light")
            } else if (savedTheme === "Light") {
                document.body.classList.add("pix-is-light")
                document.body.classList.remove("pix-is-dark")
            }
        }

        if (localStorage.getItem("darkThemeVars")) {
            setDarkThemeCode(localStorage.getItem("darkThemeVars"))
        }
        if (localStorage.getItem("lightThemeVars")) {
            setLightThemeCode(localStorage.getItem("lightThemeVars"))
        }
    }
}

const themeChecker = new ThemeCheck()

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function ThemeSwitcher(props) {
    // Config Properties
    const { defaultTheme, color, size, strokeWidth } = props

    const getInitialTheme = () => {
        if (typeof window === "undefined") {
            return defaultTheme
        }
        const savedTheme = localStorage.getItem("theme")
        if (savedTheme) {
            return savedTheme
        }
        if (defaultTheme === "System") {
            const prefersDarkScheme = window.matchMedia(
                "(prefers-color-scheme: dark)"
            ).matches
            return prefersDarkScheme ? "Dark" : "Light"
        }
        return defaultTheme
    }
    const [theme, setTheme] = useState("")
    useEffect(() => {
        setTheme(getInitialTheme())
    }, [])

    let lightIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="none"
                fontSize={size}
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
                fillRule="evenodd"
                d="M12,16 C14.209139,16 16,14.209139 16,12 C16,9.790861 14.209139,8 12,8 C9.790861,8 8,9.790861 8,12 C8,14.209139 9.790861,16 12,16 Z M3.75,12 L4,12 M20,12 L20.25,12 M6.16636906,6.16636906 L6.34314575,6.34314575 M17.6568542,17.6568542 L17.8336309,17.8336309 M12,3.75 L12,4 M12,20 L12,20.25 M17.8336309,6.16636906 L17.6568542,6.34314575 M6.34314575,17.6568542 L6.16636906,17.8336309"
            />
        </svg>
    )

    let moonIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={size}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="none"
                fontSize={size}
                stroke={color}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
                fillRule="evenodd"
                d="M10.0097755,3.50866835 C6.50909297,4.74141324 4,8.07769159 4,12 C4,16.9705627 8.02943725,21 13,21 C16.0968026,21 18.8282936,19.4359151 20.4476029,17.0546154 C12.1379474,17.2549581 7.62134541,13.421417 10.0097755,3.50866835 Z"
            />
        </svg>
    )

    if (localStorage.getItem("darkThemeVars")) {
        setDarkThemeCode(localStorage.getItem("darkThemeVars"))
    }
    if (localStorage.getItem("lightThemeVars")) {
        setLightThemeCode(localStorage.getItem("lightThemeVars"))
    }

    useEffect(() => {
        if (theme === "Dark") {
            document.body.classList.add("pix-is-dark")
            document.body.classList.remove("pix-is-light")
        } else if (theme === "Light") {
            document.body.classList.add("pix-is-light")
            document.body.classList.remove("pix-is-dark")
        }
    }, [])

    if (typeof document !== "undefined") {
        if (theme === "Dark") {
            document.body.classList.add("pix-is-dark")
            document.body.classList.remove("pix-is-light")
        } else if (theme === "Light") {
            document.body.classList.add("pix-is-light")
            document.body.classList.remove("pix-is-dark")
        }

        const savedTheme = localStorage.getItem("theme")
        if (savedTheme) {
            if (savedTheme === "Dark") {
                document.body.classList.add("pix-is-dark")
                document.body.classList.remove("pix-is-light")
            } else if (savedTheme === "Light") {
                document.body.classList.add("pix-is-light")
                document.body.classList.remove("pix-is-dark")
            }
        }

        if (localStorage.getItem("darkThemeVars")) {
            setDarkThemeCode(localStorage.getItem("darkThemeVars"))
        }
        if (localStorage.getItem("lightThemeVars")) {
            setLightThemeCode(localStorage.getItem("lightThemeVars"))
        }
    }

    useEffect(() => {
        if (theme === "Light" || theme === "Dark") {
            localStorage.setItem("theme", theme)
            const styleTags = Array.from(document.getElementsByTagName("style"))
            const themeStyleTag = styleTags.find((tag) =>
                tag.innerHTML.includes("prefers-color-scheme:")
            )
            if (themeStyleTag) {
                const darkModeCSS = extractDarkModeCSSVariables(
                    themeStyleTag.innerHTML
                )
                const lightModeCSS = extractLightModeCSSVariables(
                    themeStyleTag.innerHTML
                )
                localStorage.setItem("darkThemeVars", darkModeCSS)
                localStorage.setItem("lightThemeVars", lightModeCSS)
                setDarkThemeCode(darkModeCSS)
                setLightThemeCode(lightModeCSS)
                if (theme === "Dark") {
                    document.body.classList.add("pix-is-dark")
                    document.body.classList.remove("pix-is-light")
                } else if (theme === "Light") {
                    document.body.classList.add("pix-is-light")
                    document.body.classList.remove("pix-is-dark")
                }
            }
        }
    }, [theme])

    const handleReload = (data) => {
        setTheme(data)
    }

    useEffect(() => {
        eventEmitter.on("reload", handleReload)
        // Cleanup the event listener on component unmount
        return () => {
            eventEmitter.off("reload", handleReload)
        }
    }, [])

    const switchTheme = (e) => {
        e.preventDefault()
        let newTheme = theme === "Light" ? "Dark" : "Light"
        setTheme((prevTheme) => {
            return prevTheme === "Light" ? "Dark" : "Light"
        })
        eventEmitter.emit("reload", newTheme)
    }

    return (
        <motion.div
            style={{}}
            whileHover={{ scale: 0.98, opacity: 0.8, cursor: "pointer" }}
            transition={{ type: "spring", stiffness: 500, damping: 30 }}
        >
            <a
                style={containerStyle}
                onClick={switchTheme}
                href="#"
                aria-label="Theme switch"
            >
                {theme ? (theme === "Dark" ? moonIcon : lightIcon) : null}
            </a>
        </motion.div>
    )
}

addPropertyControls(ThemeSwitcher, {
    defaultTheme: {
        type: ControlType.Enum,
        options: ["Light", "Dark", "System"],
        optionTitles: ["Light", "Dark", "System (auto)"],
        title: "Default Theme",
        defaultValue: "System",
        description:
            "Theme Switcher will work only in live preview (the icon showing in Framer editor is a placeholder).",
    },
    color: { type: ControlType.Color, defaultValue: "#09090b" },
    size: { type: ControlType.Number, defaultValue: 30 },
    strokeWidth: {
        type: ControlType.Number,
        defaultValue: 2,
        max: 3,
        min: 0.5,
    },
})

const containerStyle = {
    cursor: "pointer",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
}
